import { addItemToCart as cart } from './cart';

// @ts-ignore
import { DateTime } from 'luxon';

declare const luxon: typeof DateTime;

interface Variation {
    type: string;
    production_year: string;
    deliverable_from: string | null;
    bottles: {
        size: string;
        price: string;
    }[]
}


export default (id: string, variations?: Variation[]) => {
    variations = (variations || []).sort((a, b) => b.production_year.localeCompare(a.production_year));

    return {
        id,
        variations,
        year: '',
        size: '',
        amount: '1',
        added: false,

        init() {
            // @ts-ignore
            this.$watch('year', () => {
                const variation = this.variation();
                this.size = variation && variation.bottles ? variation.bottles[0].size : '';
            });

            // @ts-ignore
            this.$watch('year', () => this.added = false);
            // @ts-ignore
            this.$watch('size', () => this.added = false);
            // @ts-ignore
            this.$watch('amount', () => this.added = false);

            if (this.variations.length) {
                this.year = this.variations[0].production_year;
            }
        },

        variation() {
            return this.variations.find(v => {
                return v.production_year === this.year;
            });
        },

        deliverableFrom(): string | null {
            const variation = this.variation();

            if (!variation || !variation.deliverable_from) {
                return null;
            }

            return luxon.fromISO(variation.deliverable_from).setLocale('de').toFormat('MMMM yyyy');
        },

        bottles() {
            const variation = this.variation();

            return variation && variation.bottles ? variation.bottles.sort((a, b) => +a.size - +b.size) : [];
        },

        price() {
            const bottle = this.bottles().find(b => b.size === this.size);

            if (!bottle) {
                return null;
            }

            const price = +bottle.price;
            const total = price * Math.max(1, +this.amount);

            return (+total).toFixed(2);
        },

        addToCart() {
            cart({
                id: this.id,
                year: +this.year,
                size: +this.size,
                amount: +this.amount
            });

            this.added = true;
        }
    };
};