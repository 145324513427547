import Vue from 'vue';
// import store from './store'
// import { i18n } from './translation'
import './components';

if (process.env === 'development') {
  Vue.config.devtools = true;
} else {
  Vue.config.productionTip = false;
}

Vue.prototype.$bus = new Vue();

export default (node: Element) =>
  new Vue({
    el: node
    // store,
    // i18n
  });
