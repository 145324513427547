import barba from '@barba/core';
// import {gsap} from 'gsap';
import {onLoad} from '@/scripts/onload';

barba.hooks.leave(() => {
  document.body.classList.add('is-animating');
  document.body.classList.add('animation-leave');
});
//
barba.hooks.afterLeave((data: any) => {
  document.body.classList.remove('animation-leave');
  document.body.setAttribute('data-template', data.next.namespace);
});

barba.hooks.beforeEnter((data: any) => {
  onLoad();

  window.dispatchEvent(new Event('barba.beforeEnter'));

  if (data.current.namespace !== 'wines/detail' || data.next.namespace !== 'wines/detail') {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }

  if (data.next.html.contains('alpine.form')) {
    (window as any).alpine.form.init();
  }
});
//
barba.hooks.enter((data: any) => {
  document.body.classList.add('animation-enter');
});
//
barba.hooks.afterEnter((data: any) => {
  document.body.classList.remove('animation-enter');
  document.body.classList.remove('is-animating');
});

const MagazineDuration = 0.5;

barba.init({
  // transitions: [
  //   {
  //     from: {
  //       namespace: 'wines/detail'
  //     },
  //     to: {
  //       namespace: 'wines/detail'
  //     },
  //     sync: true,
  //     async leave({current}) {
  //       await Promise.all([
  //         gsap.set(current.container.querySelector('.column-content  > .container'), {
  //           opacity: 0
  //         }),
  //         gsap.to(current.container.querySelector('.column-image'), {
  //           duration: MagazineDuration,
  //           opacity: 0
  //         }),
  //         gsap.to(current.container.querySelector('.column-image'), {
  //           duration: MagazineDuration,
  //           x: '-10px'
  //         })
  //       ]);
  //     },
  //     enter({current, next}) {
  //       gsap.set(current.container, {
  //         height: next.container.clientHeight,
  //         minHeight: 'unset',
  //         maxHeight: next.container.clientHeight,
  //       });
  //
  //       gsap.set(next.container.querySelector('.column-image'), {x: '-10px', opacity: 0});
  //
  //       gsap.to(next.container.querySelector('.column-image'), {
  //         duration: MagazineDuration,
  //         x: '0vw',
  //         opacity: 1
  //       });
  //     },
  //   }
  // ]
});
