/*!
* FitText.js 1.0 jQuery free version
*
* Copyright 2011, Dave Rupert http://daverupert.com
* Released under the WTFPL license
* http://sam.zoy.org/wtfpl/
* Modified by Slawomir Kolodziej http://slawekk.info
*
* Date: Tue Aug 09 2011 10:45:54 GMT+0200 (CEST)
*/


const addEvent = (el: Element | Window, type: string, fn: { (): void; (): void; }) => {
  el.addEventListener(type, fn, false);
};

const extend = (obj: any, ext: any) => {
  for (const key in ext) {
    if (ext.hasOwnProperty(key)) {
      obj[key] = ext[key];
    }
  }

  return obj;
};

const fit = (element: HTMLElement, settings: any) => {
  const resizer = () => {
    element.style.fontSize = Math.max(Math.min(element.clientWidth / (settings.compressor * 10), parseFloat(settings.maxFontSize)), parseFloat(settings.minFontSize)) + 'px';
  };

  // Call once to set.
  resizer();

  // Bind events
  // If you have any js library which support Events, replace this part
  // and remove addEvent function (or use original jQuery version)
  addEvent(window, 'resize', resizer);
  addEvent(window, 'orientationchange', resizer);
};

export const fitText = (elements: NodeListOf<HTMLElement> | HTMLElement | null, ratio: number, options?: any) => {
  if (!elements || (elements instanceof  NodeList && elements.length === 0)) {
    return;
  }

  const settings = extend({
    'minFontSize': -1 / 0,
    'maxFontSize': 1 / 0,
    compressor: ratio || 1
  }, options);

  if (elements instanceof NodeList && elements.length) {
    for (const element of elements) {
      fit(element, settings);
    }
  } else {
    fit(elements as HTMLElement, settings);
  }

  // return set of elements
  return elements;
};
