import { AxiosInstance } from 'axios';
import form from './form';
import autosize from '@/scripts/onload/autosize';
// @ts-ignore
import { DateTime } from 'luxon';

declare const luxon: typeof DateTime;
declare const axios: AxiosInstance;

const storageKey = 'cart';

let winesCache: Wine[] | null | undefined;

export interface Bottle {
    size: string;
    price: string;
}

export interface ProductVariation {
    production_year: string;
    deliverable_from: string;
    bottles: Bottle[];
}

export interface Wine {
    id: string;
    title: string;
    slug: string;
    order: number;
    product_variations: ProductVariation[]
}

export interface CartItem {
    id: string;
    year: number;
    size: number;
    amount: number;
}

export interface Cart {
    items?: CartItem[];
}

export interface CartProduct {
    wine: Wine;
    item: CartItem;
    variation: ProductVariation;
    bottle: Bottle;
    total: number;
    deliverableFrom: string | null;

    /**
     * The form input name.
     */
    name: string;
}

export const getCart = (): Cart => JSON.parse(localStorage.getItem(storageKey) || '{}') || {};

export const saveCart = (cart: Cart) => {
    localStorage.setItem(storageKey, JSON.stringify(cart));
    window.dispatchEvent(new CustomEvent('cart.saved'));
};

export const addItemToCart = (item: CartItem) => {
    const cart = getCart();
    const {items = []} = cart;
    const existingItem = items.find(i => i.id === item.id && i.year === item.year && i.size === item.size);

    if (existingItem) {
        existingItem.amount = existingItem.amount + item.amount;
    } else {
        items.push(item);
    }

    saveCart({
        ...cart,
        items
    });
};

export const setCartItem = (item: CartItem) => {
    const cart = getCart();
    const {items = []} = cart;
    const existingItem = items.find(i => i.id === item.id && i.year === item.year && i.size === item.size);

    if (!existingItem) {
        return;
    }

    existingItem.amount = item.amount;

    saveCart({
        ...cart,
        items
    });
};

export const getWines = (): Promise<Wine[] | null> => {
    if (winesCache === undefined) {
        winesCache = null;

        return axios
            .get('/!/wine/wines')
            .then(res => {
                winesCache = res.data;

                document.dispatchEvent(new CustomEvent('wines.fetched'));

                return res.data;
            });
    }

    return Promise.resolve(winesCache);
};

export const toCartProduct = (wines: Wine[] | null, item: CartItem): CartProduct => {
    if (!Array.isArray(wines)) {
        throw new Error('Wines have not been fetched yet!');
    }

    if (!item) {
        throw new Error('Item is not defined!');
    }

    const wine = wines.find(w => w.id === item.id);

    if (!wine) {
        throw new Error('Wine not found!');
    }

    const variation = wine.product_variations.find(v => +v.production_year === item.year);

    if (!variation) {
        throw new Error('Variation not found!');
    }

    const bottle = variation.bottles.find(b => +b.size === item.size);

    if (!bottle) {
        throw new Error('Bottle not found!');
    }

    const total = +bottle.price * item.amount;
    const name = `wines[${wine.slug}][${variation.production_year}][${bottle.size}]`;
    const deliverableFrom = variation.deliverable_from ? luxon.fromISO(variation.deliverable_from).setLocale('de').toFormat('MMMM yyyy') : null;

    return {
        wine,
        name,
        item,
        deliverableFrom,
        variation,
        bottle,
        total
    };
};

export const filterItems = (wines: Wine[] | null, items: CartItem[]) => {
    const products = items
        .map(item => {
            try {
                return toCartProduct(wines, item);
            } catch (e) {
                return null;
            }
        })
        .filter(item => item !== null && item.item.amount > 0) as CartProduct[];

    return products.sort((a, b) => {
        const title = a.wine.order - b.wine.order;
        const year = +a.variation.production_year - +b.variation.production_year;
        const size = +a.bottle.size - +b.bottle.size;

        return title || year || size;
    });
};

export default () => ({
    ...form,
    initForm: form.init,
    formSubmit: form.submit,

    wines: null as Wine[] | null,
    items: [] as CartItem[],

    init() {
        this.initWines();
        this.initCart();
        this.initForm();

        document.addEventListener('wines.fetched', () => this.initWines());
    },

    isReadyAndHasItems() {
        return this.filteredItems().length > 0 && this.wines && !this.success;
    },

    initWines() {
        getWines().then(wines => {
            this.wines = wines;

            // @ts-ignore
            this.$nextTick(() => autosize());
        });
    },

    initCart() {
        const cart = getCart();
        const {items = []} = cart;
        this.items = items;
    },

    updateAmount(product: CartProduct, amount: number) {
        setCartItem({
            id: product.wine.id,
            year: +product.variation.production_year,
            size: +product.bottle.size,
            amount
        });

        this.initCart();
    },

    removeProduct(product: CartProduct) {
        if (!confirm('Soll das Produkt aus dem Warenkorb entfernt werden?')) {
            return;
        }

        setCartItem({
            id: product.wine.id,
            year: +product.variation.production_year,
            size: +product.bottle.size,
            amount: 0
        });

        this.initCart();
    },

    filteredItems() {
        return filterItems(this.wines, this.items);
    },

    getCartVariation(item: CartItem): CartProduct {
        return toCartProduct(this.wines, item);
    },

    getCartTotal() {
        let sum = 0;

        this.filteredItems().forEach((variation) => {
            sum += variation.total;
        });

        return `CHF ${sum.toFixed(2)}`;
    },

    submit(e: Event) {
        this.formSubmit(e).then(() => {
            if (this.success) {
                localStorage.removeItem(storageKey);
                window.dispatchEvent(new CustomEvent('cart.saved'));

                window.scrollTo({
                    top: 0,
                    behavior: 'smooth'
                });
            } else {
                const firstError = document.querySelector('.form-control.is-invalid');

                if (firstError) {
                    window.scrollTo({
                        top: window.scrollY + firstError.getBoundingClientRect().top - 150,
                        behavior: 'smooth'
                    });
                }
            }
        });
    }
});